import { Palette, circularProgressClasses, svgIconClasses } from '@mui/material';
import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';

import { ThemeMode } from '../types';
import { createPalette } from './color-palette';
import { DROVA_FONT_CONFIG, generateFontFace } from './fonts';

declare module '@mui/material/styles' {
  interface ThemeOptions {
    name: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    cautious: true;
    primary: true;
    secondary: true;
    ghost_white: true;
    ghost_solar: true;
    ghost_earth: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    caution: true;
    ghostWhite: true;
    ghostSolar: true;
    ghostEarth: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    xlarge: true;
    xxlarge: true;

    default: true;
  }
}

export function createWebsiteTheme({ name, mode }: { name: string; mode: ThemeMode }): Theme {
  const palette = createPalette(mode) as Palette;

  const themeOptions: ThemeOptions = {
    name,
    palette,
    breakpoints: {
      values: {
        xs: 0,
        sm: 431,
        md: 768,
        lg: 1280,
        xl: 1400,
      },
    },
    spacing: [2, 4, 8, 12, 16, 24, 32, 64, 80, 128, 140],
    shape: {
      borderRadius: 8,
    },
    shadows: [
      'none',
      '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)',
      '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)',
      '0px 6px 6px rgba(0, 0, 0, 0.26), 0px 10px 20px rgba(0, 0, 0, 0.19)',
      '0px 10px 10px rgba(0, 0, 0, 0.26), 0px 14px 28px rgba(0, 0, 0, 0.25)',
      '0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            ${generateFontFace('recklessNeueRegular')}
            ${generateFontFace('neutrifRegular')}
            ${generateFontFace('neutrifSemiBold')}
          `,
      },

      MuiSvgIcon: {
        defaultProps: {
          fontSize: 'default',
        },
        styleOverrides: {
          root: {
            color: palette.$text.base,
          },
        },
        variants: [
          {
            props: { fontSize: 'default' },
            style: {
              fontSize: '1.5rem',
            },
          },
          {
            props: { fontSize: 'small' },
            style: {
              fontSize: '13px',
            },
          },
          {
            props: { fontSize: 'medium' },
            style: {
              fontSize: '20px',
            },
          },
          {
            props: { fontSize: 'large' },
            style: {
              fontSize: '24px',
            },
          },
          {
            props: { fontSize: 'xlarge' },
            style: {
              fontSize: '32px',
            },
          },
          {
            props: { fontSize: 'xxlarge' },
            style: {
              fontSize: '60px',
            },
          },
        ],
      },

      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
          color: 'ghostWhite',
        },
        styleOverrides: {
          root: {
            padding: '9px',

            [`.${svgIconClasses.root}`]: {
              fontSize: '20px',
            },
          },
        },
        variants: [
          {
            props: { color: 'primary' },
            style: {
              backgroundColor: palette.$earth.$500,

              [`.${svgIconClasses.root}`]: {
                color: palette.$white,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$earth.$400,
              },

              '&:active': {
                backgroundColor: palette.$earth.$500,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette.$earth.$100,

                [`.${svgIconClasses.root}`]: {
                  color: palette.$white,
                },
              },
            },
          },
          {
            props: { color: 'caution' },
            style: {
              backgroundColor: palette.$red.$600,

              [`.${svgIconClasses.root}`]: {
                color: palette.$solar.$50,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$red.$700,
              },

              '&:active': {
                backgroundColor: palette.$red.$700,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette.$red.$100,

                [`.${svgIconClasses.root}`]: {
                  color: palette.$white,
                },
              },
            },
          },
          {
            props: { color: 'secondary' },
            style: {
              backgroundColor: 'transparent',
              boxShadow: `inset 0px 0px 0px 1px ${palette.$earth.$500}`,

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$solar.$600,
                boxShadow: `inset 0px 0px 0px 1px ${palette.$earth.$500}`,
              },

              '&:active': {
                backgroundColor: palette.$solar.$700,
                boxShadow: `inset 0px 0px 0px 1px ${palette.$earth.$500}`,
              },

              '&:disabled': {
                opacity: 1,
                boxShadow: `inset 0px 0px 0px 1px ${palette.$earth.$200}`,

                [`.${svgIconClasses.root}`]: {
                  color: palette.$earth.$200,
                },
              },
            },
          },
          {
            props: { color: 'ghostWhite' },
            style: {
              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$solar.$600,
              },

              '&:active': {
                backgroundColor: palette.$solar.$700,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette.$solar.$500,

                [`.${svgIconClasses.root}`]: {
                  color: palette.$earth.$200,
                },
              },
            },
          },
          {
            props: { color: 'ghostSolar' },
            style: {
              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$solar.$600,
              },

              '&:active': {
                backgroundColor: palette.$solar.$700,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette.$solar.$500,

                [`.${svgIconClasses.root}`]: {
                  color: palette.$earth.$200,
                },
              },
            },
          },
          {
            props: { color: 'ghostEarth' },
            style: {
              [`.${svgIconClasses.root}`]: {
                color: palette.$solar.$50,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$earth.$400,
              },

              '&:active': {
                backgroundColor: palette.$earth.$500,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette.$earth.$400,

                [`.${svgIconClasses.root}`]: {
                  color: palette.$earth.$500,
                },
              },
            },
          },
        ],
      },

      MuiLink: {
        defaultProps: {
          underline: 'always',
        },
        styleOverrides: {
          root: {
            textDecorationColor: 'unset',
            color: palette.$text.base,
            cursor: 'pointer',

            ':hover': {
              color: palette.$earth.$400,
            },
          },
        },
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiButton: {
        defaultProps: {
          variant: 'primary',
        },
        styleOverrides: {
          root: {
            borderRadius: '100px',
          },
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              backgroundColor: palette.$earth.$500,
              color: palette.$white,
              boxShadow: 'none',

              [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                color: palette.$white,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$earth.$400,
              },

              '&:active': {
                backgroundColor: palette.$earth.$500,
              },

              '&:disabled': {
                backgroundColor: palette.$earth.$100,
                color: palette.$white,
              },
            },
          },
          {
            props: { variant: 'secondary' },
            style: {
              backgroundColor: palette.$white,
              boxShadow: `inset 0 0 0 1px ${palette.$earth.$500}`,
              color: palette.$earth.$500,
              border: 'none !important',

              [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                color: palette.$earth.$500,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$solar.$600,
              },

              '&:active': {
                backgroundColor: palette.$solar.$700,
              },

              '&:disabled': {
                backgroundColor: palette.$white,
                color: palette.$earth.$200,
                boxShadow: `inset 0 0 0 1px ${palette.$earth.$200}`,

                [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                  color: palette.$earth.$200,
                },
              },
            },
          },
          {
            props: { variant: 'ghost_white' },
            style: {
              color: palette.$earth.$500,
              backgroundColor: palette.$white,
              border: 'none !important',
              textDecoration: 'underline',

              [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                color: palette.$earth.$500,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$solar.$600,
                textDecoration: 'underline',
              },

              '&:active': {
                backgroundColor: palette.$solar.$700,
                textDecoration: 'underline',
              },

              '&:disabled': {
                color: palette.$earth.$200,

                [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                  color: palette.$earth.$200,
                },
              },
            },
          },
          {
            props: { variant: 'ghost_earth' },
            style: {
              color: palette.$solar.$50,
              backgroundColor: palette.$earth.$500,
              border: 'none !important',
              textDecoration: 'underline',

              [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                color: palette.$solar.$50,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$earth.$400,
                textDecoration: 'underline',
              },

              '&:active': {
                backgroundColor: palette.$earth.$500,
                textDecoration: 'underline',
              },

              '&:disabled': {
                color: palette.$earth.$500,
                backgroundColor: palette.$earth.$400,

                [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                  color: palette.$earth.$500,
                },
              },
            },
          },
          {
            props: { variant: 'ghost_solar' },
            style: {
              color: palette.$earth.$500,
              backgroundColor: palette.$solar.$500,
              border: 'none !important',
              textDecoration: 'underline',

              [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                color: palette.$earth.$500,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$solar.$600,
                textDecoration: 'underline',
              },

              '&:active': {
                backgroundColor: palette.$solar.$700,
                textDecoration: 'underline',
              },

              '&:disabled': {
                color: palette.$earth.$200,

                [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                  color: palette.$earth.$200,
                },
              },
            },
          },
          {
            props: { variant: 'cautious' },
            style: {
              color: palette.$white,
              backgroundColor: palette.$red.$600,

              [`.${svgIconClasses.root}, .${circularProgressClasses.svg}`]: {
                color: palette.$white,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.$red.$700,
              },

              '&:active': {
                backgroundColor: palette.$red.$700,
              },

              '&:disabled': {
                color: palette.$solar.$50,
                backgroundColor: palette.$red.$100,
              },
            },
          },
        ],
      },

      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
      },

      MuiSkeleton: {
        variants: [
          {
            props: { variant: 'rounded' },
            style: {
              borderRadius: '4px',
            },
          },
          {
            props: { variant: 'rectangular' },
            style: {
              borderRadius: '0px',
            },
          },
          {
            props: { variant: 'text' },
            style: {
              borderRadius: '0px',
            },
          },
        ],
      },
    },

    typography: {
      h1: {
        fontFamily: DROVA_FONT_CONFIG.recklessNeueRegular.family,
        fontSize: '80px',
        lineHeight: '72px',
      },
      h2: {
        fontFamily: DROVA_FONT_CONFIG.recklessNeueRegular.family,
        fontSize: '40px',
        lineHeight: '44px',
      },
      h3: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '40px',
        lineHeight: '44px',
      },
      h4: {
        fontFamily: DROVA_FONT_CONFIG.recklessNeueRegular.family,
        fontSize: '32px',
        lineHeight: '36px',
      },
      h5: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '32px',
        lineHeight: '36px',
      },
      h6: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '24px',
        lineHeight: '28px',
      },
      subtitle1: {
        fontFamily: DROVA_FONT_CONFIG.neutrifSemiBold.family,
        fontSize: '20px',
        lineHeight: '24px',
      },
      subtitle2: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '20px',
        lineHeight: '24px',

        'b, strong': {
          fontFamily: DROVA_FONT_CONFIG.neutrifSemiBold.family,
          fontWeight: 'unset',
        },
      },
      body1: {
        fontFamily: DROVA_FONT_CONFIG.neutrifSemiBold.family,
        fontSize: '16px',
        lineHeight: '20px',
      },
      body2: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '16px',
        lineHeight: '20px',

        'b, strong': {
          fontFamily: DROVA_FONT_CONFIG.neutrifSemiBold.family,
          fontWeight: 'unset',
        },
      },
      caption: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '12px',
        lineHeight: '16px',
      },
      overline: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '12px',
        lineHeight: '16px',
      },
      button: {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'none',
      },
    },
  };

  return createTheme(themeOptions);
}
