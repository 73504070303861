import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import * as React from 'react';
import appConfig from '~/config';
import type { ProductName } from '~/types';

import { Esg } from '@ansarada/glyphs/esg/Esg';
import { EditProfile } from '@ansarada/glyphs/mui-icons';
import { Help } from '@ansarada/glyphs/shared/Help';
import { Unsecure } from '@ansarada/glyphs/shared/Unsecure';
import { UserBuysideGroup as TriLineIcon } from '@ansarada/glyphs/shared/UserBuysideGroup';
import { PlatformHeader } from '@ansarada/ui/lib/platform-header/platform-header';

import { withTheme } from './hoc/withTheme';

const Header = withTheme(
  'ACELight',
  ({ userName, productName }: { userName: string; productName: ProductName }) => {
    const { logout } = useAuth0();

    const productMenu = React.useMemo(
      () => [
        [
          {
            id: 'grc',
            icon: <TriLineIcon />,
            label: 'GRC',
            onClick: () => window.open('https://www.ansarada.com/grc-software', '_blank')?.focus(),
          },
          {
            id: 'ESG',
            label: 'ESG Materiality',
            icon: <Esg />,
            onClick: () =>
              window.open('https://pulse.ansarada.com/materiality/admin/home', '_blank')?.focus(),
          },
        ],
      ],
      [],
    );

    const userMenu = React.useMemo(
      () => [
        [
          {
            id: 'manage-heading',
            isHeading: true,
            label: 'MANAGE',
          },
          {
            id: 'edit-profile',
            icon: <EditProfile />,
            label: 'Profile',
            onClick: () => window.open(`${appConfig.identityUrl ?? ''}/profile`, '_blank')?.focus(),
          },
        ],
        [
          {
            id: 'help',
            label: 'Help & support',
            icon: <Help />,
            onClick: () => window.open('https://help.ansarada.com', '_blank')?.focus(),
          },
          {
            id: 'logout',
            icon: <Unsecure />,
            label: 'Log out',
            onClick: () => {
              logout({
                returnTo: `${appConfig.auth.logoutRedirectUri}`,
              });
            },
          },
        ],
      ],
      [logout],
    );

    return (
      <Box data-test-id="header">
        <PlatformHeader
          userName={userName}
          productMenu={productMenu}
          productName={productName}
          userMenu={userMenu}
          sx={(theme) => ({
            position: 'static',
            backgroundColor: theme.palette.common.black,
          })}
        />
      </Box>
    );
  },
);

export { Header };
