import { Box, Typography, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const GroupHeader = styled(({ children, ...props }: PropsWithChildren) => {
  return (
    <Box {...props}>
      <Typography variant="overline">{children}</Typography>
    </Box>
  );
})(({ theme: { spacing, palette } }) => ({
  padding: spacing(3, 2),
  height: '40px',
  color: palette.$text.light,
}));

GroupHeader.displayName = 'GroupHeader';
export { GroupHeader };
export default GroupHeader;
