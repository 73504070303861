import { Box, BoxProps, SxProps, styled } from '@mui/material';
import cls from 'classnames';
import { merge } from 'lodash';
import { CSSProperties, FC, forwardRef } from 'react';

import { DROVA_FONT_CONFIG } from '@ansarada/mui-themes/lib/drova/fonts';

import { MainColor } from '../../utils/color';
import { SlotConfigProps, useSlot } from '../../utils/slot';
import { TruncatedTypographyWithTooltip } from '../TruncatedTypographyWithTooltip';

type BaseProps = {
  color?: MainColor;
  disablePropagation?: boolean;
  truncate?: boolean;
  maxWidth?: CSSProperties['maxWidth'];
} & SlotConfigProps<'start' | 'end'>;

export type BaseLozengeProps = BaseProps & Omit<BoxProps, keyof BaseProps>;

export const Start: FC<BoxProps> = () => null;
export const End: FC<BoxProps> = () => null;

const DEFAULT_MAX_WIDTH = '200px';

export const baseLozengeClasses = {
  start: 'BaseLozenge__start',
  end: 'BaseLozenge__end',
  root: 'BaseLozenge__root',
  typo: 'BaseLozenge__typo',
};

const BaseLozenge = styled(
  forwardRef<HTMLDivElement, BaseLozengeProps>(
    (
      {
        children,
        slotConfig,
        onClick,
        disablePropagation = true,
        truncate = true,
        maxWidth: _maxWidth = DEFAULT_MAX_WIDTH,
        ...props
      },
      ref,
    ) => {
      const { start, end, unslotted } = useSlot(
        children,
        merge({ start: Start, end: End }, slotConfig),
      );

      const startProps = start[0]?.props as BoxProps | undefined;
      const endProps = end[0]?.props as BoxProps | undefined;

      return (
        <Box
          {...merge(
            {
              component: 'span',
            } as typeof props,
            props,
          )}
          ref={ref}
          className={cls(props?.className, baseLozengeClasses.root)}
          onClick={(e) => {
            if (disablePropagation) {
              e.stopPropagation();
            }

            onClick?.(e);
            e.currentTarget.blur();
          }}
        >
          {!!start.length && (
            <Box {...startProps} className={cls(startProps?.className, baseLozengeClasses.start)} />
          )}

          <TruncatedTypographyWithTooltip
            className={baseLozengeClasses.typo}
            variant="caption"
            noWrap={truncate}
          >
            {unslotted}
          </TruncatedTypographyWithTooltip>

          {!!end.length && (
            <Box {...endProps} className={cls(endProps?.className, baseLozengeClasses.end)} />
          )}
        </Box>
      );
    },
  ),
)(({ theme: { palette }, color, maxWidth = DEFAULT_MAX_WIDTH }) => {
  const chiefColor = palette[color || '$earth'] || palette.$earth;

  return {
    height: '20px',
    width: 'max-content',

    color: palette.$text.light,
    backgroundColor: 'transparent',

    borderRadius: '10px',
    border: `1px solid ${chiefColor.$700}`,
    paddingInline: '10px',
    paddingBlock: '4px',

    fontSize: '12px',
    lineHeight: '12px',
    fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,

    [`.${baseLozengeClasses.typo}`]: {
      maxWidth,
      display: 'inline-block',
      lineHeight: '12px',
    },

    [`.${baseLozengeClasses.start}`]: {},

    [`.${baseLozengeClasses.end}`]: {},
  } satisfies SxProps;
});

BaseLozenge.displayName = 'BaseLozenge';
export { BaseLozenge };
