export const DROVA_FONT_CONFIG = {
  neutrifLight: {
    fileName: 'NeutrifPro-Light.ttf',
    family: 'NeutrifPro Light',
  },
  neutrifRegular: {
    fileName: 'NeutrifPro-Regular.ttf',
    family: 'NeutrifPro Regular',
    fallback: `"Times New Roman", Times, serif`,
  },
  neutrifSemiBold: {
    fileName: 'NeutrifPro-SemiBold.ttf',
    family: 'NeutrifPro SemiBold',
    fallback: `"Times New Roman", Times, serif`,
  },
  recklessNeueBold: {
    fileName: 'RecklessNeue-Bold.woff',
    family: 'RecklessNeue Bold',
  },
  recklessNeueBoldItalic: {
    fileName: 'RecklessNeue-BoldItalic.woff',
    family: 'RecklessNeue Bold Italic',
  },
  recklessNeueBook: {
    fileName: 'RecklessNeue-Book.woff',
    family: 'RecklessNeue Book',
  },
  recklessNeueBookItalic: {
    fileName: 'RecklessNeue-BookItalic.woff',
    family: 'RecklessNeue Book Italic',
  },
  recklessNeueHeavy: {
    fileName: 'RecklessNeue-Heavy.woff',
    family: 'RecklessNeue Heavy',
  },
  recklessNeueHeavyItalic: {
    fileName: 'RecklessNeue-HeavyItalic.woff',
    family: 'RecklessNeue Heavy Italic',
  },
  recklessNeueItalics: {
    fileName: 'RecklessNeue-Italics.woff',
    family: 'RecklessNeue Italic',
  },
  recklessNeueLight: {
    fileName: 'RecklessNeue-Light.woff',
    family: 'RecklessNeue Light',
  },
  recklessNeueLightItalic: {
    fileName: 'RecklessNeue-LightItalic.woff',
    family: 'RecklessNeue Light Italic',
  },
  recklessNeueMedium: {
    fileName: 'RecklessNeue-Medium.woff',
    family: 'RecklessNeue Medium',
  },
  recklessNeueMediumItalic: {
    fileName: 'RecklessNeue-MediumItalic.woff',
    family: 'RecklessNeue Medium Italic',
  },
  recklessNeueRegular: {
    fileName: 'RecklessNeue-Regular.woff',
    family: 'RecklessNeue Regular',
  },
  recklessNeueRegularItalic: {
    fileName: 'RecklessNeue-RegularItalic.woff',
    family: 'RecklessNeue Regular Italic',
  },
  recklessNeueSemiBold: {
    fileName: 'RecklessNeue-SemiBold.woff',
    family: 'RecklessNeue Semi-Bold',
  },
  recklessNeueSemiBoldItalic: {
    fileName: 'RecklessNeue-SemiBoldItalic.woff',
    family: 'RecklessNeue Semi-Bold Italic',
  },
  recklessNeueThin: {
    fileName: 'RecklessNeue-Thin.woff',
    family: 'RecklessNeue Thin',
  },
  recklessNeueThinItalic: {
    fileName: 'RecklessNeue-ThinItalic.woff',
    family: 'RecklessNeue Thin Italic',
  },
  recklessNeueUprights: {
    fileName: 'RecklessNeue-Uprights.woff',
    family: 'RecklessNeue Uprights',
  },
};

const FontExtensionFormat = {
  woff2: 'woff2',
  woff: 'woff',
  ttf: 'truetype',
  otf: 'opentype',
};

type FontExtension = keyof typeof FontExtensionFormat;

const BASE_STATIC_URL = 'https://static.cdn.dev1.drova.com/assets/fonts';

function getFontUrl(filename: string) {
  const [_, extension] = filename.split('.') as FontExtension[];
  return `url(${BASE_STATIC_URL}/${filename}) format('${FontExtensionFormat[extension]}')`;
}

export function generateFontFace(type: keyof typeof DROVA_FONT_CONFIG) {
  const { family, fileName } = DROVA_FONT_CONFIG[type];

  return `
      @font-face {
        font-family: ${family};
        src: ${getFontUrl(fileName)};
      }
    `;
}
