import { ChevronRightRounded } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  SxProps,
  Typography,
  formHelperTextClasses,
  selectClasses,
  styled,
  svgIconClasses,
  useTheme,
} from '@mui/material';
import {
  ComponentProps,
  MouseEvent,
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useId,
  useMemo,
  useRef,
} from 'react';

import { DROVA_FONT_CONFIG } from '@ansarada/mui-themes/lib/drova/fonts';

import { UtilityProps } from '../../utils/prop';
import { mergeRefs } from '../../utils/ref';
import { Spinner } from '../Spinner';
import ClearIcon from './internal/ClearIcon';
import { getChildrenLabels, transformChildrenArray } from './utils';

type TextFieldProps = {
  /** The label of the component */
  label?: ReactNode;

  /** If `true`, show `None` option in the dropdown of `Select` mode */
  noneOption?: boolean;

  /** The icon to be displayed at the end of input */
  icon?: ReactNode;

  /** If `true`, the icon is marked as active */
  iconActive?: boolean;

  /** The function runs when clicking on icon */
  onIconClick?: (e: MouseEvent<HTMLButtonElement>) => void;

  /** If `true`, the component will turn into erroneous state */
  error?: boolean;

  /** The message to be displayed when being in erroneous state */
  errorMessage?: ReactNode;

  /** If `true`, the component will turn into loading state */
  loading?: boolean;

  /** If `true`, a clear button will be displayed at the end of input allowing clearing the value of the component */
  clearable?: boolean;

  /** The function runs when clear button is clicked */
  onClear?: () => void;
} & UtilityProps;

type Props = TextFieldProps & Omit<ComponentProps<typeof MuiTextField>, '' | keyof TextFieldProps>;

type InputRefProps = HTMLInputElement & { node?: { offsetWidth: number } };

/** This component inherits [MUI TextField's API](https://mui.com/material-ui/api/text-field/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-form-text--single-line)
 */
const TextField = styled(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        label,
        icon,
        className,
        error,
        errorMessage,
        noneOption,
        clearable,
        loading = false,
        onIconClick,
        onClear,
        ...props
      }: Props,
      ref,
    ) => {
      const inputRef = useRef<InputRefProps>(null);
      const labelRef = useRef<HTMLLabelElement>(null);

      const {
        shape: { borderRadius },
        spacing,
      } = useTheme();

      const generatedId = useId();
      const id = props.id || generatedId;

      const selectChildren = useMemo(
        () =>
          transformChildrenArray(
            props.children as ReactElement,
            props['data-ansarada-ccd'],
            noneOption,
          ),
        [props['data-ansarada-ccd'], noneOption, props.children],
      );

      const labels = useMemo(() => getChildrenLabels(selectChildren), [selectChildren]);

      const focusOnClear = useCallback(() => {
        onClear?.();
        inputRef.current?.focus();
      }, [onClear]);

      const inputEndAdornmentFromRenderProps = props?.InputProps?.endAdornment;

      const inputEndAdornment = useMemo(() => {
        if (loading) {
          return <Spinner size={16} />;
        }

        if (props.select) {
          return undefined;
        }

        if (clearable && icon) {
          return (
            <>
              <ClearIcon onClick={focusOnClear} />
              <InputAdornment position="end">
                <IconButton
                  color="ghostWhite"
                  disabled={props.disabled}
                  className="button-icon"
                  disableRipple
                  onClick={onIconClick}
                >
                  {icon}
                </IconButton>
              </InputAdornment>
            </>
          );
        }

        if (clearable) {
          return <ClearIcon onClick={focusOnClear} />;
        }

        if (icon) {
          return (
            <InputAdornment position="end">
              <IconButton
                color="ghostWhite"
                disabled={props.disabled}
                className="button-icon"
                disableRipple
                onClick={onIconClick}
              >
                {icon}
              </IconButton>
            </InputAdornment>
          );
        }

        return inputEndAdornmentFromRenderProps;
      }, [
        loading,
        props.select,
        props.disabled,
        clearable,
        icon,
        focusOnClear,
        onIconClick,
        inputEndAdornmentFromRenderProps,
      ]);

      return (
        <Box className={className}>
          {(label || errorMessage) && (
            <Typography
              width="100%"
              component="label"
              variant="caption"
              noWrap
              htmlFor={id}
              ref={labelRef}
            >
              {error ? errorMessage || label : label}
            </Typography>
          )}

          <MuiTextField
            {...props}
            variant={props.variant}
            id={id}
            label={null}
            children={props.select ? selectChildren : props.children}
            ref={ref}
            error={error || false}
            InputProps={{
              ...(props.InputProps || {}),
              endAdornment: inputEndAdornment,
              inputRef: mergeRefs(inputRef, props.InputProps?.inputRef, props.inputRef),
              // To stop the overlap on the arrow
              sx: { pr: props.select ? '24px' : undefined },
            }}
            SelectProps={{
              ...(props.SelectProps || {}),
              IconComponent: ({ className }) =>
                loading ? null : (
                  <ChevronRightRounded
                    className={className}
                    sx={({ palette }) => ({
                      color: props.disabled ? palette.$grey.$500 : palette.$earth.$500,
                      rotate: '90deg',

                      [`&.${selectClasses.iconOpen}`]: {
                        transform: 'none !important',
                      },
                    })}
                  />
                ),
              MenuProps: {
                ...(props.SelectProps?.MenuProps || {}),
                PaperProps: {
                  sx: ({ palette }) => ({
                    marginTop: 2,
                    boxShadow: `inset 0px 0px 0px 1px ${palette.$light_grey.$600} !important`,
                    padding: spacing(3),
                    borderRadius: `${borderRadius}px !important`,
                  }),
                },
              },

              // Workaround, since MuiSelect has no placeholder
              ...(!!props.placeholder && {
                displayEmpty: true,
                renderValue: (v) => {
                  const value = props.value ?? v;

                  if (typeof value === 'string' && Boolean(value)) {
                    return labels.find((item) => item?.value === value)?.label;
                  }

                  if (Array.isArray(value)) {
                    return value
                      .map((value) => labels.find((item) => item?.value === value)?.label)
                      .join(', ');
                  }

                  return <span className="placeholder">{props.placeholder}</span>;
                },
              }),
            }}
          />
        </Box>
      );
    },
  ),
)(({
  disabled,
  multiline,
  select,
  icon,
  error,
  InputProps,
  theme: {
    shape: { borderRadius },
    spacing,
    palette,
  },
}) => {
  const { readOnly } = InputProps || {};

  return {
    display: 'flex',
    flexDirection: 'column',

    [`.${formHelperTextClasses.root}`]: {
      marginInline: '0px',
      color: `${palette.$text.light} !important`,
    },

    '& .MuiInputBase-root': {
      minHeight: '40px !important',
    },

    ...(multiline && {
      '& .MuiInputBase-root': {
        padding: '0px !important',
        height: 'unset !important',
      },
    }),

    ...(icon && {
      '& .MuiInputBase-root': {
        paddingRight: '0px !important',
      },
    }),

    '& input, & textarea': {
      fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
      padding: spacing(2, 3),

      ...(disabled && {
        color: `${palette.$earth.$200} !important`,
        WebkitTextFillColor: `${palette.$earth.$200} !important`,
      }),

      '&::placeholder': {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        color: `${palette.$earth.$300} !important`,
        opacity: 1,
        transform: 'translateY(2px)',

        ...(disabled && {
          color: `${palette.$earth.$200} !important`,
        }),
      },
    } as SxProps,

    '& label': {
      fontSize: '12px',
      display: 'inline-block',
      color: palette.$text.light,

      ...(error && {
        color: palette.$functional.text.error,
        WebkitTextFillColor: `${palette.$functional.text.error} !important`,
        opacity: 1,
      }),

      ...(disabled && {
        color: palette.$earth.$200,
        WebkitTextFillColor: `${palette.$earth.$200} !important`,
        opacity: 1,
      }),
    } as SxProps,

    '& fieldset': {
      border: 'unset !important',
      boxShadow: `inset 0 0 0 1px ${palette.$solar.$800}`,
      borderRadius: borderRadius / 2,

      ...(error && {
        boxShadow: `inset 0 0 0 1px ${palette.$red.$500}`,
      }),

      ...(disabled && {
        boxShadow: `inset 0 0 0 1px ${palette.$earth.$100}`,
      }),
    } as SxProps,

    ...(!disabled &&
      !readOnly && {
        '& .MuiInputBase-root:hover fieldset, & .Mui-focused fieldset': {
          boxShadow: `inset 0 0 0 1px ${palette.$solar.$900} !important`,
        },
      }),

    ...(!disabled &&
      error && {
        '& .MuiInputBase-root:hover fieldset, & .Mui-focused fieldset': {
          boxShadow: `inset 0 0 0 1px ${palette.$red.$500} !important`,
        },
      }),

    '& .button-icon': {
      borderRadius: 'unset !important',
      height: '40px !important',
      aspectRatio: 1,

      [`&:disabled .${svgIconClasses.root}`]: {
        color: palette.$earth.$200,
      },
    } as SxProps,

    ...(select && {
      '& .MuiSelect-select': {
        fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
        padding: `${spacing(2, 3)} !important`,

        ...(disabled && {
          WebkitTextFillColor: `${palette.$text.base} !important`,
          color: `${palette.$text.base} !important`,
        }),
      },

      '& .placeholder': {
        color: `${palette.$earth.$300} !important`,
        opacity: 1,

        ...(disabled && {
          color: `${palette.$earth.$200} !important`,
        }),
      },

      '& > div svg': {
        top: 'unset !important',
      },
    }),
  };
});

export { TextField };
