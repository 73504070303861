import { PaletteOptions } from '@mui/material';

import { ColorFromPalette } from '@ansarada/colors/index';

import { ThemeMode } from '../types';

export type DrovaColorPalette = {
  $50: ColorFromPalette;
  $100: ColorFromPalette;
  $200: ColorFromPalette;
  $300: ColorFromPalette;
  $400: ColorFromPalette;
  $500: ColorFromPalette;
  $600: ColorFromPalette;
  $700: ColorFromPalette;
  $800: ColorFromPalette;
  $900: ColorFromPalette;
};

interface ExtendedPalette {
  // CORE
  $dawn: DrovaColorPalette;
  $solar: DrovaColorPalette;
  $earth: DrovaColorPalette;
  $white: ColorFromPalette;

  // SECONDARY
  $light_grey: DrovaColorPalette;
  $grey: DrovaColorPalette;
  $blue: DrovaColorPalette;
  $sky_blue: DrovaColorPalette;
  $teal: DrovaColorPalette;
  $green: DrovaColorPalette;
  $yellow: DrovaColorPalette;
  $orange: DrovaColorPalette;
  $red: DrovaColorPalette;
  $pink: DrovaColorPalette;
  $purple: DrovaColorPalette;

  $text: {
    base: ColorFromPalette;
    light: ColorFromPalette;
    feature: ColorFromPalette;
    feature_dark: ColorFromPalette;
    disabled: ColorFromPalette;
    placeholder: ColorFromPalette;

    error: ColorFromPalette;
  };

  $functional: {
    text: {
      info: ColorFromPalette;
      error: ColorFromPalette;
      warning: ColorFromPalette;
      success: ColorFromPalette;
    };

    bg_borders: {
      info: ColorFromPalette;
      error: ColorFromPalette;
      warning: ColorFromPalette;
      success: ColorFromPalette;

      divider: ColorFromPalette;
    };
  };
}

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    // CORE
    $dawn: DrovaColorPalette;
    $solar: DrovaColorPalette;
    $earth: DrovaColorPalette;
    $white: ColorFromPalette;

    // SECONDARY
    $light_grey: DrovaColorPalette;
    $grey: DrovaColorPalette;
    $blue: DrovaColorPalette;
    $sky_blue: DrovaColorPalette;
    $teal: DrovaColorPalette;
    $green: DrovaColorPalette;
    $yellow: DrovaColorPalette;
    $orange: DrovaColorPalette;
    $red: DrovaColorPalette;
    $pink: DrovaColorPalette;
    $purple: DrovaColorPalette;

    $text: {
      base: ColorFromPalette;
      light: ColorFromPalette;
      feature: ColorFromPalette;
      feature_dark: ColorFromPalette;

      disabled: ColorFromPalette;
      placeholder: ColorFromPalette;

      error: ColorFromPalette;
    };

    $functional: {
      text: {
        info: ColorFromPalette;
        error: ColorFromPalette;
        warning: ColorFromPalette;
        success: ColorFromPalette;
      };

      bg_borders: {
        info: ColorFromPalette;
        error: ColorFromPalette;
        warning: ColorFromPalette;
        success: ColorFromPalette;

        divider: ColorFromPalette;
      };
    };
  }
}

export const EXTENDED_PALETTE: ExtendedPalette = {
  $dawn: {
    $50: '#FFE6D8' as ColorFromPalette,
    $100: '#FFCAA6' as ColorFromPalette,
    $200: '#FFA673' as ColorFromPalette,
    $300: '#FF8041' as ColorFromPalette,
    $400: '#FF6620' as ColorFromPalette,
    $500: '#FF4F01' as ColorFromPalette,
    $600: '#E64801' as ColorFromPalette,
    $700: '#BF3C01' as ColorFromPalette,
    $800: '#993201' as ColorFromPalette,
    $900: '#802A01' as ColorFromPalette,
  },
  $solar: {
    $50: '#FFFFFF' as ColorFromPalette,
    $100: '#F9F9F8' as ColorFromPalette,
    $200: '#FBFCF9' as ColorFromPalette,
    $300: '#F3F4F6' as ColorFromPalette,
    $400: '#E8E9DD' as ColorFromPalette,
    $500: '#F6F7F0' as ColorFromPalette,
    $600: '#E9EAE1' as ColorFromPalette,
    $700: '#D6D8D3' as ColorFromPalette,
    $800: '#ACADA8' as ColorFromPalette,
    $900: '#7B7C78' as ColorFromPalette,
  },
  $earth: {
    $50: '#E1E6E8' as ColorFromPalette,
    $100: '#BCC8D0' as ColorFromPalette,
    $200: '#A0AEB7' as ColorFromPalette,
    $300: '#7A8C97' as ColorFromPalette,
    $400: '#3F4E66' as ColorFromPalette,
    $500: '#001C27' as ColorFromPalette,
    // Don't use
    $600: '' as ColorFromPalette,
    $700: '' as ColorFromPalette,
    $800: '' as ColorFromPalette,
    $900: '' as ColorFromPalette,
  },
  $white: '#ffffff' as ColorFromPalette,

  // ---------

  $light_grey: {
    $50: '#FFFFFF' as ColorFromPalette,
    $100: '#F1F1F1' as ColorFromPalette,
    $200: '#E5E1E1' as ColorFromPalette,
    $300: '#DDDDDD' as ColorFromPalette,
    $400: '#D5D2D2' as ColorFromPalette,
    $500: '#C4C2C2' as ColorFromPalette,
    $600: '#8E9089' as ColorFromPalette,
    $700: '#656662' as ColorFromPalette,
    $800: '#4F4F4F' as ColorFromPalette,
    $900: '#3A3A3A' as ColorFromPalette,
  },
  $grey: {
    $50: '#E0E0E0' as ColorFromPalette,
    $100: '#CCCCCC' as ColorFromPalette,
    $200: '#B3B3B3' as ColorFromPalette,
    $300: '#8C8C8C' as ColorFromPalette,
    $400: '#6B6B6B' as ColorFromPalette,
    $500: '#4A4A4A' as ColorFromPalette,
    $600: '#3D3D3D' as ColorFromPalette,
    $700: '#2F2F2F' as ColorFromPalette,
    $800: '#1F1F1F' as ColorFromPalette,
    $900: '#121212' as ColorFromPalette,
  },
  $blue: {
    $50: '#E0F0FF' as ColorFromPalette,
    $100: '#B3D9FF' as ColorFromPalette,
    $200: '#80C1FF' as ColorFromPalette,
    $300: '#4DA9FF' as ColorFromPalette,
    $400: '#268FFF' as ColorFromPalette,
    $500: '#0076FF' as ColorFromPalette,
    $600: '#0144E6' as ColorFromPalette,
    $700: '#013ABF' as ColorFromPalette,
    $800: '#012F99' as ColorFromPalette,
    $900: '#012580' as ColorFromPalette,
  },
  $green: {
    $50: '#E0FFE7' as ColorFromPalette,
    $100: '#B3FFD1' as ColorFromPalette,
    $200: '#80FFBA' as ColorFromPalette,
    $300: '#4DFFA3' as ColorFromPalette,
    $400: '#26FF8F' as ColorFromPalette,
    $500: '#00FF7B' as ColorFromPalette,
    $600: '#01E648' as ColorFromPalette,
    $700: '#01BF3C' as ColorFromPalette,
    $800: '#019932' as ColorFromPalette,
    $900: '#017D2A' as ColorFromPalette,
  },
  $orange: {
    $50: '#FFE7D9' as ColorFromPalette,
    $100: '#FFCCAD' as ColorFromPalette,
    $200: '#FFB280' as ColorFromPalette,
    $300: '#FF9953' as ColorFromPalette,
    $400: '#FF8B2A' as ColorFromPalette,
    $500: '#FF7502' as ColorFromPalette,
    $600: '#E66702' as ColorFromPalette,
    $700: '#BF5702' as ColorFromPalette,
    $800: '#994602' as ColorFromPalette,
    $900: '#7D3801' as ColorFromPalette,
  },
  $pink: {
    $50: '#FCE4E9' as ColorFromPalette,
    $100: '#FBC0DA' as ColorFromPalette,
    $200: '#F68FBC' as ColorFromPalette,
    $300: '#F56DB5' as ColorFromPalette,
    $400: '#F05BC7' as ColorFromPalette,
    $500: '#FB00C9' as ColorFromPalette,
    $600: '#D6008F' as ColorFromPalette,
    $700: '#BF0076' as ColorFromPalette,
    $800: '#A6005C' as ColorFromPalette,
    $900: '#8A0047' as ColorFromPalette,
  },
  $purple: {
    $50: '#F5E0FF' as ColorFromPalette,
    $100: '#E0B3FF' as ColorFromPalette,
    $200: '#C980FF' as ColorFromPalette,
    $300: '#B34DFF' as ColorFromPalette,
    $400: '#A026FF' as ColorFromPalette,
    $500: '#8F00FF' as ColorFromPalette,
    $600: '#7D00E6' as ColorFromPalette,
    $700: '#6900BF' as ColorFromPalette,
    $800: '#550099' as ColorFromPalette,
    $900: '#450080' as ColorFromPalette,
  },
  $red: {
    $50: '#FFE0E9' as ColorFromPalette,
    $100: '#FFB3C9' as ColorFromPalette,
    $200: '#FF80A7' as ColorFromPalette,
    $300: '#FF4D85' as ColorFromPalette,
    $400: '#FF2668' as ColorFromPalette,
    $500: '#FF0052' as ColorFromPalette,
    $600: '#E6004A' as ColorFromPalette,
    $700: '#BF0040' as ColorFromPalette,
    $800: '#990033' as ColorFromPalette,
    $900: '#80002A' as ColorFromPalette,
  },
  $sky_blue: {
    $50: '#E0F8FF' as ColorFromPalette,
    $100: '#B3ECFF' as ColorFromPalette,
    $200: '#80E0FF' as ColorFromPalette,
    $300: '#4DD4FF' as ColorFromPalette,
    $400: '#26CAFF' as ColorFromPalette,
    $500: '#00BFFF' as ColorFromPalette,
    $600: '#01A6E6' as ColorFromPalette,
    $700: '#018BBF' as ColorFromPalette,
    $800: '#017299' as ColorFromPalette,
    $900: '#015980' as ColorFromPalette,
  },
  $teal: {
    $50: '#E0FFFA' as ColorFromPalette,
    $100: '#B3FFED' as ColorFromPalette,
    $200: '#80FFDF' as ColorFromPalette,
    $300: '#4DFFD2' as ColorFromPalette,
    $400: '#26FFBF' as ColorFromPalette,
    $500: '#00FFAC' as ColorFromPalette,
    $600: '#01E6AD' as ColorFromPalette,
    $700: '#01BF91' as ColorFromPalette,
    $800: '#019978' as ColorFromPalette,
    $900: '#017D63' as ColorFromPalette,
  },
  $yellow: {
    $50: '#FFFCE0' as ColorFromPalette,
    $100: '#FFF6B3' as ColorFromPalette,
    $200: '#FFF080' as ColorFromPalette,
    $300: '#FFEA4D' as ColorFromPalette,
    $400: '#FFE526' as ColorFromPalette,
    $500: '#FFE000' as ColorFromPalette,
    $600: '#E6CC01' as ColorFromPalette,
    $700: '#BFA301' as ColorFromPalette,
    $800: '#998301' as ColorFromPalette,
    $900: '#806900' as ColorFromPalette,
  },

  // ---------

  $text: {
    base: '#001c27' as ColorFromPalette,
    light: '#3F4E66' as ColorFromPalette,
    feature_dark: '#bf2b00' as ColorFromPalette,
    feature: '#ff3a00' as ColorFromPalette,
    disabled: '#A0AEB7' as ColorFromPalette,
    placeholder: '#7A8C97' as ColorFromPalette,
    error: '#E6004A' as ColorFromPalette,
  },
  $functional: {
    text: {
      error: '#e6004a' as ColorFromPalette,
      info: '#2b9eba' as ColorFromPalette,
      success: '#2bba39' as ColorFromPalette,
      warning: '#ff4f01' as ColorFromPalette,
    },
    bg_borders: {
      divider: '#DDDED8' as ColorFromPalette,
      info: '#01a6e6' as ColorFromPalette,
      error: '#ff0052' as ColorFromPalette,
      warning: '#bf2b00' as ColorFromPalette,
      success: '#01e648' as ColorFromPalette,
    },
  },
};

export function createPalette(mode: ThemeMode): PaletteOptions {
  return {
    ...EXTENDED_PALETTE,

    error: {
      main: EXTENDED_PALETTE.$functional.bg_borders.error,
    },
    warning: {
      main: EXTENDED_PALETTE.$functional.bg_borders.warning,
    },
    success: {
      main: EXTENDED_PALETTE.$functional.bg_borders.success,
    },
    info: {
      main: EXTENDED_PALETTE.$functional.bg_borders.info,
    },
    text: {
      primary: EXTENDED_PALETTE.$text.base,
      secondary: EXTENDED_PALETTE.$text.feature,
      disabled: EXTENDED_PALETTE.$grey.$200,
    },
    background: {
      default: EXTENDED_PALETTE.$white,
      paper: EXTENDED_PALETTE.$white,
    },
    mode,
    common: {
      black: EXTENDED_PALETTE.$earth.$500,
      white: EXTENDED_PALETTE.$white,
    },
    grey: {
      100: EXTENDED_PALETTE.$grey.$100,
      200: EXTENDED_PALETTE.$grey.$200,
      300: EXTENDED_PALETTE.$grey.$300,
      400: EXTENDED_PALETTE.$grey.$400,
      500: EXTENDED_PALETTE.$grey.$500,
      600: EXTENDED_PALETTE.$grey.$600,
      700: EXTENDED_PALETTE.$grey.$700,
    },
    divider: EXTENDED_PALETTE.$functional.bg_borders.divider,
  };
}
