import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { Logo } from '../sections/signup-section';

interface Props {
  title: string;
  content: React.ReactNode;
  renderAction: () => React.ReactNode;
}

export const ResetMFALayout = (props: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: '#f7f7f7',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 620,
          margin: '0 auto',
          [theme.breakpoints.up('sm')]: {
            padding: '20px 60px',
          },
        }}
      >
        <ResetMFAWidget {...props} />
      </Box>
    </Box>
  );
};

const ResetMFAWidget = (props: Props) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        minHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <Card elevation={0} sx={{ width: 500 }}>
        <Stack minHeight={540} justifyContent="center" alignItems="center">
          <Box textAlign="center" flexShrink={0} padding={theme.spacing(5, 5)}>
            <Logo />
            <Typography variant="h6" data-test-id="reset-mfa-title" my={5}>
              {props.title}
            </Typography>
            <Typography variant="body2" data-test-id="reset-mfa-content" maxWidth={350}>
              {props.content}
            </Typography>
          </Box>

          <Stack gap={3} padding={theme.spacing(0, 5, 6)} flexShrink={0}>
            {props.renderAction()}
          </Stack>
        </Stack>
      </Card>
      <Box textAlign="center" marginTop={4}>
        <Stack gap={6} direction="row" color={theme.palette.common.black}>
          <Typography fontSize={14}>
            <Link
              href="https://help.ansarada.com/en/collections/6616951-multi-factor-authentication-mfa"
              rel="noopener"
              underline="always"
              target="_blank"
              sx={{
                backgroundColor: 'transparent',
                textDecorationColor: theme.palette.common.black,
              }}
            >
              What is MFA?
            </Link>
          </Typography>
          <Typography fontSize={14}>
            <Link
              href="https://www.ansarada.com/terms-conditions"
              rel="noopener"
              underline="always"
              target="_blank"
              sx={{
                backgroundColor: 'transparent',
                textDecorationColor: theme.palette.common.black,
              }}
            >
              Terms
            </Link>{' '}
            and{' '}
            <Link
              href="https://www.ansarada.com/privacy"
              rel="noopener"
              underline="always"
              target="_blank"
              sx={{
                backgroundColor: 'transparent',
                textDecorationColor: theme.palette.common.black,
              }}
            >
              Privacy
            </Link>
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
