import { SxProps, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { NotificationAPI, NotificationProvider, useNotification } from 'rc-notification';
import { ComponentProps, Key, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { Toast } from '..';

type RendererProps = {
  duration?: number;
  maxCount?: number;
  className?: string;
};

type RendererRef = NotificationAPI;

let keyIndex = 0;

const Renderer = styled(
  forwardRef<RendererRef, RendererProps>((props, ref) => {
    const { maxCount = 3, duration = 2, className } = props;
    const [api, renderer] = useNotification({
      duration,
      maxCount,
      closable: false,
      closeIcon: null,
      className: () => className ?? '',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getContainer: () => document.getElementById('ans-toast-container')!,
      renderNotifications(node, { key }) {
        return <NotificationProvider key={key}>{node}</NotificationProvider>;
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        ...api,
      }),
      [api],
    );

    return renderer;
  }),
)(({ theme }) => {
  return {
    position: 'fixed',
    bottom: '48px',
    right: '48px',
    zIndex: theme.zIndex.modal + 1,

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    '.rc-notification-notice-content': {
      backgroundColor: 'transparent',
    },
  } satisfies SxProps;
});

type ArgsProps = ComponentProps<typeof Toast>;

export type ToastAPI = {
  close: (key: Key) => void;
  destroy: (key?: Key) => void;
  open: (args: ArgsProps) => string;
};

export function useInternalToast(config?: RendererProps) {
  const rendererRef = useRef<RendererRef>(null);
  const api = useMemo<ToastAPI>(() => {
    const close = (key: Key) => {
      rendererRef.current?.close(key);
    };

    const open = (props: ArgsProps) => {
      if (!rendererRef.current) return '';
      const { open: originOpen } = rendererRef.current;
      const key = `ans-toast-${++keyIndex}`;

      originOpen({
        duration: props.duration,
        content: (
          <motion.div
            initial={{ opacity: 0, x: '40px' }}
            animate={{ opacity: 1, x: '0px' }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          >
            <Toast {...props} />
          </motion.div>
        ),
        key,
      });

      return key;
    };

    const destroy = (key?: Key) => {
      if (key) {
        rendererRef.current?.close(key);
        return;
      }

      rendererRef.current?.destroy();
    };

    return { close, open, destroy };
  }, []);

  return [api, <Renderer {...(config ?? {})} ref={rendererRef} />] as const;
}
