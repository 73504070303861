import { useAuth0 } from '@auth0/auth0-react';
import Link from '@mui/material/Link';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { withTheme } from '~/components/hoc/withTheme';
import { ResetMFALayout } from '~/components/layout/reset-mfa-layout';
import { Progress } from '~/components/progress';
import { MessageSection } from '~/components/sections/message-section';
import config from '~/config';
import { useResetMFA } from '~/hook/use-reset-mfa';

import { Button } from '@ansarada/components/Button';

export const ResetMFAComplete = withTheme('DrovaLight', () => {
  const [searchParams] = useSearchParams();
  const { logout } = useAuth0();
  const { isLoading, isSuccess } = useResetMFA({
    variables: { token: searchParams.get('token') ?? '' },
  });

  const goToLogin = () => {
    logout({
      returnTo: config.auth.logoutRedirectUri,
    });
  };

  const ContactSupportLink = (
    <Link href="https://www.ansarada.com/contact-support">contact us</Link>
  );

  return isLoading ? (
    <MessageSection title={<Progress />} />
  ) : isSuccess ? (
    <ResetMFALayout
      title="You're good to go!"
      content={<span>Your access has been reset and you will need to log back in.</span>}
      renderAction={() => (
        <Button disabled={isLoading} loading={isLoading} onClick={goToLogin}>
          Login
        </Button>
      )}
    />
  ) : (
    <MessageSection title="Reset MFA Error" subtitle={`Error code: Invalid`}>
      <p data-test-id="mfa-reset-error">
        {'This link is invalid or expired.'}
        <br />
        Please {ContactSupportLink} for more information!
      </p>
    </MessageSection>
  );
});
